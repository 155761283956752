
(function() {
    //uscca cookies
    var USCCA_ACCESS_TOKEN = 'uscca_access';
    var USCCA_SESSION_TOKEN = 'uscca_session';

    // store can only read pantheon cookie
    var USCCA_PANTHEON_ACCESS_TOKEN  = 'SESSusccaaccess';
    var USCCA_PANTHEON_SESSION_TOKEN = 'SESSusccasession';

    function getCookie(name) {
        var re = new RegExp(name + '=([^;]+)');
        var value = re.exec(document.cookie);
        return value != null ? unescape(value[1]) : null;
    }
      
    function setCookie(cname, cvalue, exdays) {
        var domain = window.location.hostname.split('.')[1]; 
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;secure;domain=" + '.' + domain + '.com' ;
    }
    
    function setPantheonCookie() {
        var usccaAccessToken = getCookie(USCCA_ACCESS_TOKEN);
        var usccaSessionToken = getCookie(USCCA_SESSION_TOKEN);
        var pantheonAccessToken = getCookie(USCCA_PANTHEON_ACCESS_TOKEN);
        var pantheonSessionToken = getCookie(USCCA_PANTHEON_SESSION_TOKEN);

        /*
        if there is no uscca access and no uscca session token OR
        if there is a pantheon access and a pantheon session token exist THEN
        remove the pantheon cookies  
        */
        if(usccaAccessToken === null && usccaSessionToken === null) {
            if (pantheonAccessToken && pantheonSessionToken) {
                // delete pantheon cookie if the usccaAccess and session aren't available
                setCookie(USCCA_PANTHEON_ACCESS_TOKEN, '', 0);
                setCookie(USCCA_PANTHEON_SESSION_TOKEN, '', 0);
                return
            }
        }

        /*
        if there is a uscca access and a uscca session token AND 
        if a pantheon access and a pantheon session token doesn't exist THEN 
        set the pantheon token and refresh page 
        */
        if((usccaAccessToken && usccaSessionToken) && (!pantheonAccessToken && !pantheonSessionToken)) {
            setCookie(USCCA_PANTHEON_ACCESS_TOKEN, usccaAccessToken, 7);
            setCookie(USCCA_PANTHEON_SESSION_TOKEN, usccaSessionToken, 7);
            
            window.location.reload()
        }
    }

    function ready(callback){
        // in case the document is already rendered
        if (document.readyState!='loading') {
            callback();
        } else if (document.addEventListener) {
            document.addEventListener('DOMContentLoaded', callback);
        }
        
    };
    


    ready(function() { 
        setPantheonCookie();
    });
  })();
